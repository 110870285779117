




















































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { validationMixin } from "vuelidate";
import { Component } from "vue-property-decorator";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";

type LoginType = {
  email: string;
  password: string;
};

const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,16}$/;
const regexMessage =
  "Password requires at least one uppercase letter, one lowercase letter and one number";
@Component({
  mixins: [validationMixin],
  validations: {
    emailInput: { required, email },
    passwordInput: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(16),
    },
  },
})
export default class Login extends Vue {
  @Getter("profile/loading") loading!: boolean;
  @Action("clearEverything") clearEverything!: (reroute: boolean) => void;
  @Action("profile/loginUser") loginUser!: (data: LoginType) => Promise<void>;

  showPass = false;
  emailInput = "";
  passwordInput = "";

  get emailErrors(): any[] {
    const errors: any[] = [];
    if (!this.$v.emailInput!.$dirty) return errors;
    !this.$v.emailInput!.email &&
      errors.push(this.$t("loginSection.invalidEmail"));
    !this.$v.emailInput!.required &&
      errors.push(this.$t("loginSection.emailRequired"));
    return errors;
  }

  get passwordErrors(): any[] {
    const errors: any[] = [];
    if (!this.$v.passwordInput!.$dirty) return errors;
    !this.$v.passwordInput!.required &&
      errors.push(this.$t("loginSection.passRequired"));
    !this.$v.passwordInput!.minLength &&
      errors.push(this.$t("loginSection.passMin"));
    !this.$v.passwordInput!.maxLength &&
      errors.push(this.$t("loginSection.passMax"));
    !regex.test(this.passwordInput) && errors.push(regexMessage);
    return errors;
  }

  submit(): void {
    this.$v.$touch();
    if (!this.$v.$invalid && this.passwordErrors.length === 0) {
      this.clearEverything(false);
      this.loginUser({ email: this.emailInput, password: this.passwordInput });
    }
  }

  beforeDestroy() {
    this.emailInput = "";
    this.passwordInput = "";
  }
}
